<template>
    <div>
        <b-row v-if="permissions.includes('warehouse-item-inquiry')">
            <!-- card collapsible  -->
            <b-col md="12">
                <b-card-actions
                title="Filter"
                :collapsed="true"
                action-collapse
                >
                    <b-row>
                        <b-col cols="3">
                            <b-form-group
                            label="Name"
                            label-for="name"
                            >
                            <b-form-input
                                id="name"
                                placeholder="Name"
                                v-model="filter.name"
                                required
                            />
                            </b-form-group>
                        </b-col>
                        <b-col md="3">
                            <b-form-group
                            label="Warehouse"
                            label-for="warehouse"
                            >
                                <b-form-select
                                id="warehouse"
                                v-model="filter.selectedWarehouse"
                                :options="filter.optionsWarehouse"
                                />
                                <!-- Selected: <strong>{{ filter.selectedWarehouse }}</strong> -->
                            </b-form-group>
                        </b-col>
                        <b-col cols="3">
                            <b-form-group
                            label="Code"
                            label-for="code"
                            >
                            <b-form-input
                                id="code"
                                placeholder="Code"
                                v-model="filter.code"
                                required
                            />
                            </b-form-group>
                        </b-col>
                        <b-col md="12">
                            <b-button
                            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                            variant="primary"
                            class="mt-1"
                            @click="getInquiry(1, filter.name, filter.selectedWarehouse, filter.code)"
                            >
                            Search
                            </b-button>
                        </b-col>
                    </b-row>
                </b-card-actions>
            </b-col>
        </b-row>
        <b-row v-if="permissions.includes('warehouse-item-create')">
            <b-col class="text-right mb-1">
                <router-link to="/warehouse/item/add">
                    <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="success"
                    pill
                    class="px-3"
                    >
                    Add Item
                    </b-button>
                </router-link>
            </b-col>
        </b-row>
        <b-card v-if="permissions.includes('warehouse-item-inquiry')">
            <!-- search input -->
            <!-- <b-row>
                <b-col class="custom-search d-flex justify-content-end">
                    <b-form-group>
                        <div class="d-flex align-items-center">
                            <label class="mr-1">Search</label>
                            <b-form-input
                                v-model="searchTerm"
                                placeholder="Search"
                                type="text"
                                class="d-inline-block"
                            />
                        </div>
                    </b-form-group>
                </b-col>
            </b-row> -->

            <!-- table -->
            <vue-good-table
            :columns="columns"
            :rows="rows"
            :rtl="direction"
            :search-options="{
                enabled: true,
                externalQuery: searchTerm 
            }"
            :select-options="{
                enabled: false,
                selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
                selectionInfoClass: 'custom-class',
                selectionText: 'rows selected',
                clearSelectionText: 'clear',
                disableSelectInfo: true, // disable the select info panel on top
                selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
            }"
            :pagination-options="{
                enabled: true,
                perPage:pageLength
            }"
            >
                <template
                    slot="table-row"
                    slot-scope="props"
                >

                    <!-- Column: Name -->
                    <span
                    v-if="props.column.field === 'name'"
                    class="text-nowrap"
                    >
                        <!-- <b-avatar
                            :src="props.row.avatar"
                            class="mx-1"
                        /> -->
                        <span class="text-nowrap">{{ props.row.name }}</span>
                    </span>

                    <!-- Column: Action -->
                    <span v-else-if="props.column.field === 'action'">
                        <span>
                            <b-dropdown
                            variant="link"
                            toggle-class="text-decoration-none"
                            no-caret
                            >
                                <template v-slot:button-content>
                                    <feather-icon
                                    icon="MoreVerticalIcon"
                                    size="16"
                                    class="text-body align-middle mr-25"
                                    />
                                </template>
                                <b-dropdown-item v-bind:to="{path: '/warehouse/item/' +props.row.id}" v-if="permissions.includes('warehouse-item-detail')">
                                    <feather-icon
                                    icon="Edit2Icon"
                                    class="mr-50"
                                    />
                                    <span>Detail</span>
                                </b-dropdown-item>
                            </b-dropdown>
                        </span>
                    </span>

                    <!-- Column: Common -->
                    <span v-else>
                    {{ props.formattedRow[props.column.field] }}
                    </span>
                </template>

                <!-- pagination -->
                <template
                    slot="pagination-bottom"
                >
                    <div class="d-flex justify-content-between flex-wrap">
                        <div class="d-flex align-items-center mb-0 mt-1">
                            <span class="text-nowrap ">
                            Showing {{ meta.from }} to {{ meta.to }}
                            </span>
                            <span class="text-nowrap">&nbsp;of {{ meta.total }} entries </span>
                        </div>
                        <div>
                            <b-pagination
                            :value="1"
                            :total-rows="meta.total"
                            :per-page="meta.per_page"
                            first-number
                            last-number
                            align="right"
                            prev-class="prev-item"
                            next-class="next-item"
                            class="mt-1 mb-0"
                            @input="(value)=>getInquiry(value, filter.name, filter.selectedWarehouse, filter.code)"
                            >
                            <template #prev-text>
                                <feather-icon
                                icon="ChevronLeftIcon"
                                size="18"
                                />
                            </template>
                            <template #next-text>
                                <feather-icon
                                icon="ChevronRightIcon"
                                size="18"
                                />
                            </template>
                            </b-pagination>
                        </div>
                    </div>
                </template>
            </vue-good-table>
        </b-card>
    </div>
</template>

<script>
import {
    BForm, BFormFile, BModal, BButton, BRow, BCol, BCard, BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'

export default {
    components: {
        BCardActions,
        VueGoodTable,
        ToastificationContent,
        ValidationProvider,
        ValidationObserver,
        BForm,
        BFormFile,
        BModal,
        BButton,
        BRow,
        BCol,
        BCard, 
        BAvatar,
        BBadge,
        BPagination,
        BFormGroup,
        BFormInput,
        BFormSelect,
        BDropdown,
        BDropdownItem,
    },
    directives: {
        Ripple,
    },
    data() {
        return {
            pageLength: 10,
            dir: false,
            columns: [
                {
                    label: 'Code',
                    field: 'code',
                },
                {
                    label: 'Product Name',
                    field: 'product_name',
                },
                {
                    label: 'Quantity',
                    field: 'qty',
                },
                {
                    label: 'Warehouse',
                    field: 'warehouse_name',
                },
                {
                    label: 'Action',
                    field: 'action',
                    width: '100px'
                },
            ],
            filter: {
                name: '',
                selectedWarehouse: '',
                optionsWarehouse: [],
                code: '',
            },
            search: '',
            rows: [],
            searchTerm: '',
            meta: {
                current_page: 1,
                last_page: 0,
                from: 0,
                to: 0,
                per_page: 10,
                total: 0,
            },
            permissions: [],
            errors: '',
            errMessage: '',
            file: null
        }
    },
    computed: {
        statusVariant() {
            const statusColor = {
                1 : 'light-success',
                0 : 'light-danger'
            }

            return status => statusColor[status]
        },
        direction() {
            if (store.state.appConfig.isRTL) {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.dir = true
                return this.dir
            }
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.dir = false
            return this.dir
        },
    },
    created() {
        this.getAllPermissions()
        this.getInquiry(1, "", "", "")
        this.getWarehouse()
    },
    methods: {
        getAllPermissions() {
            this.permissions = sessionStorage.getItem('permissions')
        },
        getInquiry($page, $name, $warehouse, $code) {
            this.$http.get('warehouse/items?page=' + $page + ($name ? "&name="+this.filter.name : "" ) + ($warehouse ? "&warehouse="+this.filter.selectedWarehouse : "" ) + ($code ? "&code="+this.filter.code : "" ))
            .then(res => { 
                this.rows = res.data.data
                // console.log(res.data.data)
                var meta = res.data.meta
                if (meta.from != null) {
                    this.meta.from = meta.from
                }
                if (meta.to != null) {
                    this.meta.to = meta.to
                }
                this.meta.current_page = meta.current_page
                this.meta.last_page = meta.last_page
                this.meta.per_page = meta.per_page
                this.meta.total = meta.total
            }).catch((errors) => {
                this.errMessage = errors.response.data.message
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Notification',
                        icon: 'BellIcon',
                        text: this.errMessage,
                        variant: 'warning',
                    },
                })
                console.log(errors.response)
            })
        },
        getWarehouse() {
            this.$http.get('warehouses')
            .then(response => { 
                // console.log(response.data.data)
                this.filter.optionsWarehouse.push({value: '', text: 'All'})
                
                var datas = response.data.data
                var i
                for(i=0; i<datas.length; i++) {
                    var $data = {
                        value: datas[i].id,
                        text: datas[i].name
                    }
                    // console.log($data)
                    this.filter.optionsWarehouse.push($data)
                }
                // console.log(this.filter.optionsWarehouse)
            }).catch((errors) => {
                this.errMessage = errors.response.data.message
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Notification',
                        icon: 'BellIcon',
                        text: this.errMessage,
                        variant: 'warning',
                    },
                })
                console.log(errors.response)
            })
        },
    },
}
</script>

<style lang="scss" >
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>